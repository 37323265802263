<template>
    <div>
        <div style="padding:20px 24px 0px 24px">
            <div class="globalText middle">生理年龄与免疫年龄可能存在差异，这即是同龄人中有人“更健康”的原因。</div>
        </div>
        <div class="box globalText" style="line-height: 30px;">
            您本次检出的免疫功能水平优于<span style="color:#FF830F;margin:0 4px;">95%</span>的同年龄段人群
        </div>
        <img :src="ossUrl + 'age.png'" alt="" style="width:100%" />
        <div style="padding:0 24px 40px 24px;">
            <div class="globalTitle" style="color:#FF830F;margin:30px 0 16px 0">指标解释</div>
            <div class="globalText">
                <p style="margin-bottom: 14px;">
                    在同年龄段人群中，使用T细胞多样性指数建立免疫年龄基线坐标体系。将检测者T细胞多样性指数与基线坐标体系进行对比，即可获得该检测者在本年龄段人群中的相对坐标位置。提示在同龄人中的免疫“地位”。
                </p>大数据表明：健康人的免疫力会随着年龄的增高而逐渐减低，因而与同年龄段人群免疫力的比较，能较为客观评估体检者免疫力在同龄普通人群中的相对水平。
            </div>
        </div>
    </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl,
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.box {
    // width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 30px 20px;
    padding: 20px 10px 20px 24px;
    box-sizing: border-box;
}

.num {
    width: 36px;
    height: 17px;
    font-size: 12px;
    color: #05C3F8;
}
</style>
